.page-error {
    &--404 {
        main {
            padding-top: 20px;
        }

        .search-bar button {
            padding: 0 !important;
            min-width: 100px;
            width: auto;
        }

        .search-bar button .button__inner {
            min-width: auto;
        }

        .search-bar button span {
            display: inline;
        }
    }
}

@include media(lg) {
    .page-error {
        &--404 {
            main {
                padding-top: 100px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:33";